import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { openState } from 'stores/appStore';
import Welcome from './Welcome';
import ComingSoon from './ComingSoon';
import Vision from './Vision';
import Game from './Game';
import Partners from './Partners';
// import Exchanges from './Exchanges';

const Home = () => {
  const [isOpen, setIsOpen] = useRecoilState(openState);
  const endTime = dayjs('2022-08-09 12:30');

  useEffect(() => {
    if (dayjs().isAfter(endTime)) {
      setIsOpen(true);
    }
  }, [endTime, setIsOpen]);

  const { days, hours, minutes, seconds } = useTimer({
    expiryTimestamp: endTime.toDate(),
    onExpire: () => {
      setTimeout(() => {
        setIsOpen(true);
      }, 3000);
    },
  });

  return isOpen ? (
    <>
      <Welcome />
      <Vision />
      <Game />
      <Partners />
      {/* <Exchanges /> */}
    </>
  ) : (
    <>
      <Welcome />
      <ComingSoon {...{ days, hours, minutes, seconds }} />
    </>
  );
};

export default Home;
