import React from 'react';

import {
  imgParters1Png,
  imgParters2Png,
  imgParters3Png,
  imgParters4Png,
  imgParters5Png,
  imgParters6Png,
  imgParters7Png,
  imgParters8Png,
  imgParters9Png,
  imgParters10Png,
  imgParters11Png,
  imgParters12Png,
  imgParters13Png,
  imgParters14Png,
  imgParters15Png,
} from 'assets/images';

type Props = {};

const Partners: React.FC<Props> = () => {
  return (
    <section className="partners" id="partners">
      <div className="contentWrap">
        <h3 className="xl-txt sectionTitle">Partners</h3>
        <ul className="partnerListWrap">
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters1Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters2Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters3Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters4Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters5Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters6Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters7Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters8Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters9Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters10Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters11Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters12Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters13Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters14Png} alt="" />
          </li>
          <li
            className="partnerList"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={imgParters15Png} alt="" />
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Partners;
