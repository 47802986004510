import React from 'react';
import { useRoutes } from 'react-router-dom';

import Home from 'pages/home/Home';

export const useNav = () => {
  const routes = [{ path: '/', element: <Home /> }];

  return { element: useRoutes(routes) };
};
