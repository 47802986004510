import React from 'react';
import bgMp4 from 'assets/videos/bg.mp4';

const Video = () => {
  return (
    <div className="videoBg">
      <video muted autoPlay loop playsInline>
        <source src={bgMp4} type="video/mp4" />
      </video>
    </div>
  );
};

export default Video;
