import React from 'react';

import Video from './Video';

type Props = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const ComingSoon = (props: Props) => {
  const { days, hours, minutes, seconds } = props;

  return (
    <section className="comingSoon">
      <header>
        <h1>
          Coming <em>soon!</em>
        </h1>
        <h2>
          Stay tuned, big news{' '}
          <em>
            to announce on <span>Aug 9th</span>
          </em>
        </h2>
      </header>
      <section className="countDown">
        <ul>
          <li>
            <span>Days</span>
            <h1>{displayTime(days)}</h1>
          </li>
          <li>
            <span>Hours</span>
            <h1>{displayTime(hours)}</h1>
          </li>
          <li>
            <span>Minutes</span>
            <h1>{displayTime(minutes)}</h1>
          </li>
          <li>
            <span>Seconds</span>
            <h1>{displayTime(seconds)}</h1>
          </li>
        </ul>
      </section>
      <Video />
    </section>
  );
};

export default ComingSoon;

const displayTime = (value: number) => {
  const leftDigit = value >= 10 ? value.toString()[0] : '0';
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString();

  return `${leftDigit}${rightDigit}`;
};
