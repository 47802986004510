import React, { useRef, useState } from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import {
  badgeAppStorePreSvg,
  badgeAppStoreSvg,
  badgePlayStorePreSvg,
  badgePlayStoreSvg,
  badgeWindowsSvg,
  imgGame1Png,
  imgGame2Png,
  imgGame3Png,
  imgGameThumb1Png,
  imgGameThumb2Png,
  imgGameThumb3Png,
  imgGameThumb4Png,
  imgGameThumb5Png,
  imgGameThumb6Png,
  imgGameThumb7Png,
  imgGameThumbLucaPng,
} from 'assets/images';
import {
  // IcAppStoreSvg,
  IcArrowBlueSvg,
  IcHomeSvg,
  // IcPlayStoreSvg,
} from 'assets/images/icon';

SwiperCore.use([Autoplay, Navigation, Pagination]);

type Props = {};

const Game: React.FC<Props> = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [isSwiper, setIsSwiper] = useState<SwiperCore>();

  return (
    <section className="game" id="games">
      <div className="contentWrap">
        <div className="titleWrap">
          <h3 className="xl-txt sectionTitle">
            Enjoy
            <br />
            the <span>Game</span>
          </h3>
          <p className="m-txt">
            Enjoy the lineup of our games
            <br />
            with a successful track record.
            <br />
            <br />
            Stay tuned for new launches,
            <br />
            as there are many more to come!
          </p>
        </div>
        <div className="gameImgContainer">
          <div className="gameImgWrap">
            <img
              src={imgGame1Png}
              alt=""
              className="gameImg"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="300"
            />
            <img
              src={imgGame2Png}
              alt=""
              className="gameImg"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="0"
            />
            <img
              src={imgGame3Png}
              alt=""
              className="gameImg"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="200"
            />
          </div>
        </div>
      </div>
      {/* <ul className="gameListWrap">
        <li className="gameList">
          <img src={imgGameThumb1Png} alt="" />
          <p className="s-txt">Chromatic Souls: AFK Raid</p>
        </li>
        <li className="gameList">
          <img src={imgGameThumb2Png} alt="" />
          <p className="s-txt">Summoners War: Chronicles</p>
        </li>
        <li className="gameList">
          <img src={imgGameThumb3Png} alt="" />
          <p className="s-txt">Hi Ella</p>
        </li>
        <li className="gameList">
          <img src={imgGameThumb4Png} alt="" />
          <p className="s-txt">Golf Star</p>
        </li>
        <li className="gameList">
          <img src={imgGameThumb5Png} alt="" />
          <p className="s-txt">Kritika</p>
        </li>
        <li className="gameList">
          <img src={imgGameThumb6Png} alt="" />
          <p className="s-txt">Crypto SuperStars</p>
        </li>
      </ul> */}

      <div
        className="gameListContainer"
        onMouseEnter={() => {
          isSwiper?.autoplay.stop();
        }}
        onMouseLeave={() => {
          isSwiper?.autoplay.start();
        }}
      >
        <Swiper
          className="gameListWrap"
          style={{ paddingTop: '68px' }}
          slidesPerView={1}
          spaceBetween={16}
          navigation={{ prevEl: '.prev', nextEl: '.next' }}
          breakpoints={{
            '460': {
              slidesPerView: 1,
            },
            '730': {
              slidesPerView: 2,
            },
            '1150': {
              slidesPerView: 3,
            },
            '1500': {
              slidesPerView: 4,
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop
          onSwiper={(swiper) => {
            setIsSwiper(swiper);
          }}
        >
          <SwiperSlide className="gameList">
            <div className="gameLabelWrap xxs-txt">
              {/* <span className="gameLabel beta">Beta Game Launcher</span> */}
              {/* <span className="gameLabel preSale"> Updated </span> */}
            </div>

            <div className="gameListImg">
              <img src={imgGameThumb1Png} alt="" />
            </div>

            <div className="gameBottomWrap">
              <h5 className="gameTitle s-txt">Summoners War: Lost Centuria</h5>
              <div className="gameStoreBtnWrap">
                <a
                  href="https://summonerswar.com/en/lostcenturia"
                  target="_blank"
                  className="gameStoreBtn homepage"
                  rel="noreferrer"
                >
                  <img src={IcHomeSvg} alt="" />
                </a>
                <a
                  href="https://swlc.onelink.me/4U6a/464d15f3"
                  target="_blank"
                  rel="noreferrer"
                  className="gameStoreBtn play"
                >
                  <img src={badgeAppStoreSvg} alt="" />
                </a>
                <a
                  href=" https://swlc.onelink.me/BPtk/a3b9818"
                  target="_blank"
                  rel="noreferrer"
                  className="gameStoreBtn play"
                >
                  <img src={badgePlayStoreSvg} alt="" />
                </a>
                {/* <div className="gameStoreBtn comingSoon">
                  <span className="xxs-txt">Coming soon</span>
                </div> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="gameList">
            <div className="gameLabelWrap xxs-txt">
              {/* <span className="gameLabel beta">Beta Game Launcher</span> */}
            </div>

            <div className="gameListImg">
              <img src={imgGameThumb2Png} alt="" />
            </div>

            <div className="gameBottomWrap">
              <h5 className="gameTitle s-txt">Chromatic Souls : AFK Raid</h5>
              <div className="gameStoreBtnWrap">
                <a
                  href="https://chromaticsouls.c2x.world/en"
                  target="_blank"
                  className="gameStoreBtn homepage"
                  rel="noreferrer"
                >
                  <img src={IcHomeSvg} alt="" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/chromatic-souls-afk-raid/id1548602503"
                  target="_blank"
                  rel="noreferrer"
                  className="gameStoreBtn play"
                >
                  <img src={badgeAppStoreSvg} alt="" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.gamevil.idlecs.android.google.global.normal"
                  target="_blank"
                  rel="noreferrer"
                  className="gameStoreBtn play"
                >
                  <img src={badgePlayStoreSvg} alt="" />
                </a>
                {/* <div className="gameStoreBtn comingSoon">
                  <span className="xxs-txt">Coming soon</span>
                </div> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="gameList">
            <div className="gameLabelWrap xxs-txt">
              {/* <span className="gameLabel event">Homepage open</span> */}
            </div>

            <div className="gameListImg">
              <img src={imgGameThumb3Png} alt="" />
            </div>

            <div className="gameBottomWrap">
              <h5 className="gameTitle s-txt">Kritika Global</h5>
              <div className="gameStoreBtnWrap">
                <a
                  href="https://kritika-global.com/"
                  target="_blank"
                  className="gameStoreBtn homepage"
                  rel="noreferrer"
                >
                  <img src={IcHomeSvg} alt="" />
                </a>
                <a
                  href="https://kritika-global.com/Download"
                  target="_blank"
                  rel="noreferrer"
                  className="gameStoreBtn play"
                >
                  <img src={badgeWindowsSvg} alt="" />
                </a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="gameList">
            <div className="gameLabelWrap xxs-txt">
              {/* <span className="gameLabel event">Homepage open</span> */}
            </div>

            <div className="gameListImg">
              <img src={imgGameThumb6Png} alt="" />
            </div>

            <div className="gameBottomWrap">
              <h5 className="gameTitle s-txt">Baseball Superstars</h5>
              <div className="gameStoreBtnWrap">
                <a
                  href="https://baseball-superstars.com"
                  target="_blank"
                  className="gameStoreBtn homepage"
                  rel="noreferrer"
                >
                  <img src={IcHomeSvg} alt="" />
                </a>
                <a
                  href="https://apps.apple.com/app/id1396381549"
                  target="_blank"
                  rel="noreferrer"
                  className="gameStoreBtn play"
                >
                  <img src={badgeAppStoreSvg} alt="" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.gamevil.basebss.android.google.global.normal1"
                  target="_blank"
                  rel="noreferrer"
                  className="gameStoreBtn play"
                >
                  <img src={badgePlayStoreSvg} alt="" />
                </a>
                {/* <div className="gameStoreBtn comingSoon">
                  <span className="xxs-txt">Coming soon</span>
                </div> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="gameList">
            <div className="gameLabelWrap xxs-txt">
              {/* <span className="gameLabel preSale">Pre-Register</span> */}
            </div>
            <div className="gameListImg">
              <img src={imgGameThumbLucaPng} alt="" />
            </div>
            <div className="gameBottomWrap">
              <h5 className="gameTitle s-txt">IDLE LUCA</h5>

              <div className="gameStoreBtnWrap">
                <a
                  href="https://idleluca.com2us.com/"
                  target="_blank"
                  className="gameStoreBtn homepage"
                  rel="noreferrer"
                >
                  <img src={IcHomeSvg} alt="" />
                </a>
                <a
                  href="https://apps.apple.com/app/idle-luca/id1623125424"
                  target="_blank"
                  rel="noreferrer"
                  className="gameStoreBtn play"
                >
                  <img src={badgeAppStoreSvg} alt="" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=space.novacore.idleluca.aos"
                  target="_blank"
                  rel="noreferrer"
                  className="gameStoreBtn play"
                >
                  <img src={badgePlayStoreSvg} alt="" />
                </a>
                {/* <div className="gameStoreBtn comingSoon">
                  <span className="xxs-txt">Coming soon</span>
                </div> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="gameList">
            {/* <div className="gameLabelWrap xxs-txt">
              <span className="gameLabel preSale">Pre-Register</span>
            </div> */}

            <div className="gameListImg">
              <img src={imgGameThumb4Png} alt="" />
            </div>

            <div className="gameBottomWrap">
              <h5 className="gameTitle s-txt">Dear, Ella</h5>
              <div className="gameStoreBtnWrap">
                <a
                  href="https://dear-ella.c2x.world/"
                  target="_blank"
                  className="gameStoreBtn homepage"
                  rel="noreferrer"
                >
                  <img src={IcHomeSvg} alt="" />
                </a>
                <a
                  href="https://apps.apple.com/app/dear-ella/id1593133837"
                  target="_blank"
                  className="gameStoreBtn play"
                  rel="noreferrer"
                >
                  <img src={badgeAppStorePreSvg} alt="" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.gamevil.hiella.android.google.global.normal"
                  target="_blank"
                  className="gameStoreBtn play"
                  rel="noreferrer"
                >
                  <img src={badgePlayStorePreSvg} alt="" />
                </a>
                {/* <div className="gameStoreBtn comingSoon">
                  <span className="xxs-txt">Coming soon</span>
                </div> */}
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="gameList">
            <div className="gameLabelWrap xxs-txt">
              {/* <span className="gameLabel event">Homepage open</span> */}
            </div>

            <div className="gameListImg">
              <img src={imgGameThumb5Png} alt="" />
            </div>

            <div className="gameBottomWrap">
              <h5 className="gameTitle s-txt">Summoners War: Chronicles</h5>
              <div className="gameStoreBtnWrap">
                {/* <a
                  href="https://chromaticsouls.c2x.world/en"
                  target="_blank"
                  className="gameStoreBtn homepage"
                  rel="noreferrer"
                >
                  <img src={IcHomeSvg} alt="" />
                </a>
                <a href="">
                  <img src={IcAppStoreSvg} alt="" />
                </a>
                <a href="">
                  <img src={IcPlayStoreSvg} alt="" />
                </a> */}
                <div className="gameStoreBtn comingSoon">
                  <span className="xxs-txt">Coming soon</span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="gameList">
            <div className="gameLabelWrap xxs-txt">
              {/* <span className="gameLabel event">Homepage open</span> */}
            </div>

            <div className="gameListImg">
              <img src={imgGameThumb7Png} alt="" />
            </div>

            <div className="gameBottomWrap">
              <h5 className="gameTitle s-txt">Golf Star</h5>
              <div className="gameStoreBtnWrap">
                {/* <a
                  href="https://chromaticsouls.c2x.world/en"
                  target="_blank"
                  className="gameStoreBtn homepage"
                  rel="noreferrer"
                >
                  <img src={IcHomeSvg} alt="" />
                </a>
                <a href="">
                  <img src={IcAppStoreSvg} alt="" />
                </a>
                <a href="">
                  <img src={IcPlayStoreSvg} alt="" />
                </a> */}
                <div className="gameStoreBtn comingSoon">
                  <span className="xxs-txt">Coming soon</span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <div className="gameArrowWrap">
            <div ref={navigationPrevRef} className="prev gameArrow left">
              <img src={IcArrowBlueSvg} alt="" />
            </div>
            <div ref={navigationNextRef} className="next gameArrow right">
              <img src={IcArrowBlueSvg} alt="" />
            </div>
          </div>
        </Swiper>
      </div>
    </section>
  );
};

export default Game;
