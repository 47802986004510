import React from 'react';
import {
  visionGovernancePng,
  visionProductionPng,
  visionGamersPng,
  xplaLogWhiteSvg,
} from 'assets/images';
import visionBgMp4 from 'assets/videos/vision_bg.mp4';

const XPLA_URL = process.env.REACT_APP_XPLA_URL;

const Vision = () => {
  return (
    <section className="vision" id="vision">
      <div className="contentWrap vision_container">
        <header className="vision_header">
          <h1>
            Welcome to the <em>Future</em> of Gaming
          </h1>
          <p>
            We are a blockchain gaming platform where everybody is involved
            together in bringing quality web3 games out to the world.
          </p>
        </header>
        <div className="vision_video">
          <video muted autoPlay loop playsInline>
            <source src={visionBgMp4} type="video/mp4" />
          </video>
        </div>
        <section className="vision_main">
          <ul>
            <li className="vision_item">
              <h1>Governance</h1>
              <p>
                Everybody’s in this together, vote for your favorite game to
                help them launch on our platform!
              </p>
              <img src={visionGovernancePng} alt="" />
            </li>
            <li className="vision_item">
              <h1>Game Production</h1>
              <p>
                Game developers on C2X Gaming Platform produce and freely
                service games of all genres on Xpla mainnet after collecting
                extensive opinions from the community!
              </p>
              <img src={visionProductionPng} alt="" />
            </li>
            <li className="vision_item">
              <h1>Gamers</h1>
              <p>
                It will be more than just users playing a game, they will play a
                central role in developing a game and expanding the entire
                ecosystem.
              </p>
              <img src={visionGamersPng} alt="" />
            </li>
          </ul>
        </section>
        <a
          href={XPLA_URL}
          className="moveToXpla"
          target="_blank"
          rel="noreferrer"
        >
          <svg viewBox="0 0 100 100" width="100" height="100">
            <defs>
              <path
                id="circle"
                d="
                  M 50, 50
                  m -37, 0
                  a 37,37 0 1,1 74,0
                  a 37,37 0 1,1 -74,0"
              />
            </defs>
            <text fill="#fff" fontSize="12" letterSpacing="0.8px">
              <textPath xlinkHref="#circle">
                FULFILL YOUR DREAM RIGHT HERE.
              </textPath>
            </text>
          </svg>
          <div className="center">
            <img src={xplaLogWhiteSvg} alt="" />
            <i className="material-symbols-outlined">trending_flat</i>
          </div>
        </a>
      </div>
    </section>
  );
};

export default Vision;
