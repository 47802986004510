import React from 'react';
import C2XLogoPng from 'assets/images/banner/c2xLogo.png';
// import CTXTLogoPng from 'assets/images/banner/ctxtLogo.png';
import XPLALogoPng from 'assets/images/banner/xplaLogo.png';

const Welcome = () => {
  return (
    <a href="https://migration.c2x.world/" target="_blank" rel="noreferrer">
      <section className="welcome l-txt">
        <div className="welcomeTitle">
          <div className="welcome_img_wrap">
            <img src={C2XLogoPng} alt="" />
            <div className="arrow_wrap">
              <i className="material-symbols-outlined"> chevron_right </i>
              <i className="material-symbols-outlined"> chevron_right </i>
              <i className="material-symbols-outlined"> chevron_right </i>
            </div>
            <img src={XPLALogoPng} alt="" />
          </div>
          <h1>Migration</h1>
          <p>Due date : 14 Apr. 2023 06:00 (UTC)</p>
        </div>
      </section>
    </a>
  );
};

export default Welcome;
