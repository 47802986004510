import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import AOS from 'aos';
import { openState } from 'stores/appStore';
import Layout, { Content, Footer, Header } from 'components/layout/Layout';
import { useNav } from './routes';
import 'aos/dist/aos.css';

const redirect = true;
const App = () => {
  const { element: routes } = useNav();
  const isOpen = useRecoilValue(openState);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    window.location.href = 'https://xpla.games';
  }, []);

  return redirect ? (
    <></>
  ) : (
    <Layout>
      <Header />
      <Content>{routes}</Content>
      {isOpen && <Footer />}
    </Layout>
  );
};

export default App;
